import { Spinner } from '@bindystreet/bindystreet.kit.react';
import { IBase } from 'Colugo/interfaces';
import { BlockType } from 'Colugo/interfaces/lobby/discover/enums/BlockType';
import update from 'immutability-helper';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { BiPlus } from 'react-icons/bi';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { ImRadioChecked, ImRadioUnchecked } from 'react-icons/im';
import { RiDeleteBin2Line, RiTableAltLine } from 'react-icons/ri';
import {
  Cell,
  Column,
  HeaderGroup,
  Row,
  TableBodyPropGetter,
  TableBodyProps,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  useSortBy,
  useTable
} from 'react-table';
import GlobalFilter from './GlobalFilter';

type Props<T extends IBase> = {
  tableColumns: Column<T>[];
  entities: T[];
  searchPlaceholderText: string;
  children?: React.ReactNode;
  onClickManageIcon?: (entity: T) => void;
  onClickDeleteIcon?: (entity: T) => void;
  onClickAddIcon?: (entity: T) => void;
  onChangeOrder?: (entity: T, position: number) => void;
  isMutationLoading?: boolean;
  showActiveToggle?: boolean;
  onChangeSearchValue?: ((value: string) => void) | undefined;
  onClickActiveIcon?: (entity: T) => void;
  onClickRow?: (entityId: string) => void;
  // some rows are not editable, use this prop to diffrentiate them
  isEditable?: boolean;
  isBlocksTable?: boolean;
  rowSelectedId?: string;
  rowHeight?: string;
  isOrderable?: boolean;
  // this is specfically used for the the subPage blocks modal with the table, we need to position the children differently here
  isModalView?: boolean;
  isBlockPageView?: boolean;
  isFullView?: boolean;
  isBlockItems?: boolean;
  isLoading?: boolean;
  shouldShowOrderArrows?: boolean; // remove this prop later once the table component is used everywhere
  isVersionableId?: boolean;
};

// TODO: need to setup different view sizes for this table, its currently compatible for Half View by default (eg:filtergoup and combos section)
// and also the Modal View for the subPage blocks table, need to optimize for full view such as the Discovery Page Table
function Table<T extends IBase & { versionable?: { id?: string } }>(
  props: Props<T>
) {
  const {
    entities,
    tableColumns,
    onClickDeleteIcon,
    onClickManageIcon,
    onClickAddIcon,
    children,
    onClickRow,
    onChangeOrder,
    onChangeSearchValue,
    searchPlaceholderText,
    isMutationLoading,
    showActiveToggle = false,
    isEditable,
    isBlocksTable,
    isOrderable = false,
    onClickActiveIcon,
    shouldShowOrderArrows = true,
    isModalView,
    isBlockPageView,
    isFullView,
    isBlockItems,
    rowSelectedId,
    rowHeight,
    isVersionableId = false,
    isLoading = false
  } = props;

  const [localEntities, setLocalEntities] = useState(entities);
  const [rowHovered, setRowHovered] = useState(-1);
  const [rowHover, setRowHover] = useState<number | undefined>(undefined);
  const [isManageHover, setIsManageHover] = useState(false);
  const [isAddHover, setIsAddHover] = useState(false);
  const [isMoveUpHover, setIsMoveUpHover] = useState(false);
  const [isMoveDownHover, setIsMoveDownHover] = useState(false);
  const [isDeleteHover, setIsDeleteHover] = useState(false);

  useEffect(() => {
    if (entities) {
      setLocalEntities(entities);
    }
  }, [entities]);

  const moveRow = useCallback(
    async (dragIndex: number, hoverIndex: number) => {
      setLocalEntities(
        update(localEntities, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, localEntities[dragIndex]]
          ]
        })
      );

      onChangeOrder &&
        dragIndex !== hoverIndex &&
        onChangeOrder(localEntities[dragIndex], hoverIndex);
    },
    [localEntities, onChangeOrder]
  );

  interface IBaseWithType extends IBase {
    type: any;
  }

  const setClickableRowIconElements = (cell: Cell) => {
    const extendedCell = cell.row.original as T extends {
      isActive: boolean;
      versionable: { id: string };
    }
      ? T
      : never;

    const blocks = localEntities as (T & IBaseWithType)[];

    function handleClickActiveIcon(extendedCell: T) {
      onClickActiveIcon &&
        onClickActiveIcon(
          localEntities.find((le) => le.id === extendedCell.id) as T
        );
    }

    return (
      <div
        onMouseEnter={() => setRowHover(cell.row.index)}
        onMouseLeave={() => setRowHover(undefined)}
        className="flex flex-row"
      >
        {isMutationLoading && rowHover === cell.row.index && (
          <Spinner size="sm" />
        )}

        {(isEditable && !isBlocksTable) ||
        (isEditable &&
          isBlocksTable &&
          !(
            blocks[cell.row.index].type === BlockType.GuideSpots ||
            blocks[cell.row.index].type === BlockType.EntitiesGroup ||
            blocks[cell.row.index].type === BlockType.SuggestedEvents ||
            blocks[cell.row.index].type === BlockType.SuggestedGuides ||
            blocks[cell.row.index].type === BlockType.SuggestedListings ||
            blocks[cell.row.index].type === BlockType.VideosGroup
          )) ? (
          <div className="flex flex-row items-center mt-2">
            <RiTableAltLine
              className={`text-4xl cursor-pointer mx-3 ${
                rowHover === cell.row.index && isManageHover
                  ? 'text-success'
                  : 'text-theme6'
              }`}
              onClick={() => {
                onClickManageIcon &&
                  onClickManageIcon(
                    localEntities.find((e) => e.id === cell.value)!
                  );
              }}
              onMouseEnter={() => setIsManageHover(true)}
              onMouseLeave={() => setIsManageHover(false)}
            />
          </div>
        ) : (
          <></>
        )}

        {onClickAddIcon && (
          <div>
            <BiPlus
              className={`text-4xl cursor-pointer m-4 ${
                rowHover === cell.row.index && isAddHover
                  ? 'text-success'
                  : 'text-theme6'
              }`}
              onMouseEnter={() => setIsAddHover(true)}
              onMouseLeave={() => setIsAddHover(false)}
              onClick={() => {
                const cellId = isVersionableId
                  ? extendedCell.versionable?.id
                  : cell.value;

                const entity = isVersionableId
                  ? localEntities.find((e) => e.versionable?.id === cellId)
                  : localEntities.find((e) => e.id === cellId);

                if (entity) {
                  onClickAddIcon(entity);
                }
              }}
            />
          </div>
        )}

        {showActiveToggle && (
          <div className="flex flex-row items-center">
            {extendedCell.isActive ? (
              <ImRadioChecked
                color={'#27AF96'}
                className={`text-3xl cursor-pointer`}
                onClick={() => handleClickActiveIcon(extendedCell)}
              />
            ) : (
              <ImRadioUnchecked
                color={'#27AF96'}
                className={`text-3xl cursor-pointer`}
                onClick={() => handleClickActiveIcon(extendedCell)}
              />
            )}
          </div>
        )}

        {cell.row.index !== 0 && isOrderable && shouldShowOrderArrows && (
          <FaArrowUp
            className={`text-4xl cursor-pointer mx-3 mt-2 ${
              rowHover === cell.row.index && isMoveUpHover
                ? 'text-hot'
                : 'text-theme6'
            }`}
            onClick={() => {
              const entity = localEntities.find((e) => e.id === cell.value)!;
              onChangeOrder && onChangeOrder(entity, cell.row.index - 1);
            }}
            onMouseEnter={() => setIsMoveUpHover(true)}
            onMouseLeave={() => setIsMoveUpHover(false)}
          />
        )}
        {cell.row.index !== localEntities.length - 1 &&
          isOrderable &&
          shouldShowOrderArrows && (
            <FaArrowDown
              className={`text-4xl cursor-pointer mx-3 mt-2 ${
                rowHover === cell.row.index && isMoveDownHover
                  ? 'text-hot'
                  : 'text-theme6'
              }`}
              onClick={() => {
                const entity = localEntities.find((e) => e.id === cell.value)!;
                onChangeOrder && onChangeOrder(entity, cell.row.index + 1);
              }}
              onMouseEnter={() => setIsMoveDownHover(true)}
              onMouseLeave={() => setIsMoveDownHover(false)}
            />
          )}
        {onClickDeleteIcon && (
          <RiDeleteBin2Line
            className={`text-4xl cursor-pointer mx-3 mt-2 ${
              rowHover === cell.row.index && isDeleteHover
                ? 'text-error1'
                : 'text-theme6'
            }`}
            onClick={() => {
              onClickDeleteIcon(
                localEntities.find((e) => e.id === cell.value)!
              );
            }}
            onMouseEnter={() => setIsDeleteHover(true)}
            onMouseLeave={() => setIsDeleteHover(false)}
          />
        )}
      </div>
    );
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable(
    {
      columns: tableColumns,
      data: localEntities
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useFlexLayout
  );

  const onChangeSearchInput = (value: string) => {
    setGlobalFilter(value);
    onChangeSearchValue && onChangeSearchValue(value);
  };

  const table = (
    <div>
      <div {...getTableProps()} className="w-full mb-4">
        <div>{tableHeaderConfig(headerGroups)}</div>
        {comboTableRowsConfig(
          rowHovered,
          setRowHovered,
          getTableBodyProps,
          rows,
          prepareRow,
          isOrderable,
          setClickableRowIconElements,
          moveRow,
          onClickRow,
          rowSelectedId,
          rowHeight,
          isFullView
        )}
      </div>
    </div>
  );

  return (
    <div className="w-full">
      {isBlockPageView || isFullView ? (
        <div className="flex flex-col my-1 w-full items-center px-10">
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={onChangeSearchInput}
            searchPlaceholder={searchPlaceholderText}
          />
          {children}
        </div>
      ) : (
        <div
          className={`flex ${
            isModalView ? 'flex-row-reverse' : 'flex-row'
          }  items-center justify-evenly w-full px-4 h-auto`}
        >
          {children}
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            searchPlaceholder={searchPlaceholderText}
          />
        </div>
      )}

      {isBlockPageView && isBlockItems && entities.length === 0 ? (
        <div
          className="text-xl font-bold flex flex-col justify-center"
          style={{ height: '60vh' }}
        >
          {isLoading ? (
            <Spinner />
          ) : (
            <div>No Items Added to this Block yet</div>
          )}
        </div>
      ) : (
        <div>{table}</div>
      )}
    </div>
  );
}

export default Table;

const tableHeaderConfig = <T extends IBase>(headerGroups: HeaderGroup<T>[]) => {
  return headerGroups.map((headerGroup, i) => (
    <div
      {...headerGroup.getHeaderGroupProps()}
      key={i + '_parent'}
      className="h-12 font-bold leading-4 uppercase tracking-wider"
    >
      {headerGroup.headers.map((column, i) => {
        return (
          <div
            {...column.getHeaderProps(column.getSortByToggleProps())}
            key={i + 'child'}
            className="flex justify-between"
          >
            <div
              className={
                'flex flex-row items-center justify-center text-xs xl:text-sm my-auto w-full'
              }
            >
              <div className="flex justify-center items-center ml-auto mr-auto w-full">
                {column.Header!.toString() !== 'Edit'
                  ? column.render('Header')
                  : ''}
              </div>
              <div className="flex-grow"></div>
            </div>
          </div>
        );
      })}
    </div>
  ));
};

interface IDragItem {
  index: number;
  id: string;
  type: string;
}

const ItemTypes = {
  ROW: 'row'
};

const comboTableRowsConfig = <T extends IBase>(
  rowHovered: number,
  setRowHovered: (b: number) => void,
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<T> | undefined
  ) => TableBodyProps,
  rows: Row<T>[],
  prepareRow: (row: Row<T>) => void,
  isOrderable: boolean | undefined,
  setEndRowElements: (cell: Cell) => JSX.Element,
  moveRow: (dragIndex: number, hoverIndex: number) => void,
  onClickRow?: (entityId: string) => void,
  rowSelectedId?: string,
  rowHeight?: string,
  isFullView?: boolean
) => (
  <div
    className="overflow-y-auto"
    style={{ height: isFullView ? 'auto' : '60vh' }}
    onMouseLeave={() => setRowHovered(-1)}
    {...getTableBodyProps()}
  >
    <div>
      {rows.map((row, index) => {
        prepareRow(row);
        const bgcolor: string = index % 2 ? 'bg-transparent' : 'bg-theme2';
        const elementId = row.cells[row.cells.length - 1].value;

        return (
          <div key={index}>
            <DraggableRow
              row={row}
              elementId={elementId}
              bgcolor={bgcolor}
              index={index}
              rowHovered={rowHovered}
              setRowHovered={setRowHovered}
              isOrderable={isOrderable}
              setEndRowElements={setEndRowElements}
              onClickRow={onClickRow}
              moveRow={moveRow}
              rowSelectedId={rowSelectedId}
              rowHeight={rowHeight}
            />
          </div>
        );
      })}
    </div>
  </div>
);

type DraggableRowProps = {
  row: any;
  elementId: any;
  bgcolor: string;
  index: number;
  rowHovered: number;
  isOrderable: boolean | undefined;
  setRowHovered: (b: number) => void;
  setEndRowElements: (cell: Cell) => JSX.Element;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
  onClickRow?: (entityId: string) => void;
  rowSelectedId?: string;
  rowHeight?: string;
};

function DraggableRow(props: DraggableRowProps) {
  const {
    index,
    row,
    rowHovered,
    elementId,
    bgcolor,
    isOrderable,
    setEndRowElements,
    setRowHovered,
    onClickRow,
    moveRow,
    rowSelectedId,
    rowHeight
  } = props;

  const dragRef = useRef<HTMLDivElement | null>(null);
  const dropRef = useRef<HTMLDivElement | null>(null);

  const [{ isOver }, drop] = useDrop<
    IDragItem,
    void,
    { canDrop: boolean; isOver: boolean }
  >({
    accept: ItemTypes.ROW,
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver()
    }),
    drop: (item: IDragItem) => {
      moveRow(item.index, index);
    }
  });

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: ItemTypes.ROW,
      item: () => {
        return { index: index };
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging()
      }),
      canDrag: isOrderable
    }),
    [isOrderable]
  );

  const opacity = isDragging ? 0 : 1;

  preview(drop(dropRef));
  drag(dragRef);

  return (
    <div ref={dropRef} style={{ opacity }} className={`cursor-grabbing`}>
      <div
        ref={dragRef}
        {...row.getRowProps({})}
        className={`whitespace-no-wrap mt-4 items-center ${
          rowHeight ? rowHeight : 'h-16'
        } relative rounded-md ${bgcolor} ${
          isOver && 'border-t-2 border-hot'
        } hover:shadow-lg ${isOrderable ? 'cursor-grab' : 'cursor-pointer'}`}
        onMouseEnter={() => setRowHovered(index)}
        onClick={() => onClickRow && onClickRow(elementId)}
      >
        {rowHovered === index && (
          <div className="border border-theme6 bg-transparent w-full h-full absolute rounded-md pointer-events-none">
            <div
              className={`w-2 rounded-l-md bg-hot absolute left-0 top-0 bottom-0`}
            ></div>
          </div>
        )}

        {rowSelectedId === elementId && (
          <div className="border border-theme6 bg-transparent w-full h-full absolute rounded-md pointer-events-none">
            <div className="w-2 rounded-l-md bg-hot absolute left-0 top-0 bottom-0"></div>
          </div>
        )}

        {row.cells.map((cell, i) => {
          if (cell.column.Header?.toString() === 'Edit') {
            return (
              <div
                {...cell.getCellProps()}
                className="flex flex-row justify-center w-full"
                key={i}
              >
                <div className="flex flex-row justify-center mr-2">
                  {setEndRowElements(cell as any)}
                </div>
              </div>
            );
          }
          return (
            <div
              {...cell.getCellProps()}
              className="flex items-center px-2 text-left ml-2 justify-start"
            >
              {cell.render('Cell')}
            </div>
          );
        })}
      </div>
    </div>
  );
}
