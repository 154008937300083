enum AssetTypeEnum {
  Unknown = 0,
  Player = 1,
  Venue = 2
}
export enum UploadOwnerType {
  Unknown = 'Unknown',
  Creator = 'Creator',
  Player = 'Player',
  Admin = 'Player'
}

export default AssetTypeEnum;
