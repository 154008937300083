import { AssetTypeEnum } from 'Colugo/interfaces/file';
import { Lifecycle, scoped } from 'tsyringe';
import AbstractOperations from '../AbstractOperations';
import { UploadOwnerType } from 'Colugo/interfaces/file/AssetTypeEnum';

@scoped(Lifecycle.ContainerScoped)
class FileUploadOperations extends AbstractOperations {
  protected basePath: string = 'file';

  public async uploadImageAsync(
    file: File,
    handleProgressUpdate?: (number: any) => void
  ): Promise<{ error?: string; data: any }> {
    return await this.executeXHRAsync(
      'POST',
      `image/${AssetTypeEnum.Venue}`,
      file,
      handleProgressUpdate
    );
  }

  public async uploadVideoAsync(
    file: File,
    handleProgressUpdate?: (number: any) => void,
    uploadOwnerType: UploadOwnerType = UploadOwnerType.Admin
  ) {
    return await this.executeXHRAsync(
      'POST',
      `video/${uploadOwnerType}`,
      file,
      handleProgressUpdate
    );
  }
}

export default FileUploadOperations;
