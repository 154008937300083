import { Button, Spinner } from '@bindystreet/bindystreet.kit.react';
import { IVideo } from 'Colugo/interfaces/video/IVideo';
import VideoOperations, {
  useReqListVideosAsync
} from 'Colugo/operations/video/VideoOperations';
import ConfirmationPopup from 'component/utility/ConfirmationPopup';
import Table from 'component/utility/Table';
import { useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FaCopy } from 'react-icons/fa';
import ReactPlayer from 'react-player';
import { Column, Cell } from 'react-table';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';
import { copyToClipboardAsync } from 'utility/general/generalUtils';
import { ImageKit } from 'utility/general/imageKit';
import { useSearchVideos } from 'utility/hooks/useLocalSearchVideos';
const videoOperations = container.resolve(VideoOperations);

function VideoContainer() {
  const [allVideos, setAllVideos] = useState<IVideo[] | undefined>(undefined);
  const {
    data: videos,
    isLoading: isVideosLoading,
    mutate
  } = useReqListVideosAsync(true);
  const [allVideosNamesQueryValue, setAllVideosNameQueryValue] = useState<
    undefined | string
  >(undefined);
  const [videoIdToDelete, setVideoIdToDelete] = useState<string | undefined>(
    undefined
  );
  const searchVideos = useSearchVideos(videos || [], setAllVideos);

  useEffect(() => {
    if (allVideosNamesQueryValue && allVideosNamesQueryValue.length > 0) {
      searchVideos(allVideosNamesQueryValue);
    }
  }, [allVideosNamesQueryValue, searchVideos]);

  async function handleDeleteVideoAsync() {
    if (!allVideos) {
      return;
    }
    const videoToBeRemoved = allVideos.find((v) => v.id === videoIdToDelete);
    if (!videoIdToDelete || !videoToBeRemoved) {
      toast.error('Cannot find video, please try again.');
      return;
    }

    setAllVideos(allVideos.filter((v) => v.id !== videoIdToDelete));
    setVideoIdToDelete(undefined);
    const { error } = await videoOperations.deleteAsync(videoIdToDelete);

    if (error) {
      toast.error('Failed to delete video, please try again');
      return false;
    }
    toast.success('Video deleted successfully');
    mutate(videos?.filter((v) => v.id !== videoIdToDelete));
  }

  const videoGroupColumns = useMemo(() => {
    const videoGroupItemsTableColumns: Column<IVideo>[] = [
      {
        Header: 'Video',
        accessor: (row) => {
          return row?.url;
        },
        Cell: (cellInfo: { cell: Cell }) => {
          return (
            <div className="flex justify-center items-center flex-col w-full">
              {cellInfo.cell.value && (
                <div className="rounded-md object-contain w-full border-primaryTeal border-2 flex justify-center">
                  <ReactPlayer
                    url={ImageKit.withSizing(
                      ImageKit.convertVideoUrl(cellInfo.cell.value)
                    )}
                    width={300}
                    height={200}
                    controls
                  />
                </div>
              )}
            </div>
          );
        }
      },
      {
        Header: 'Caption',
        accessor: (row) => {
          return (
            <div className="flex justify-center items-center flex-col w-full">
              {row?.caption
                ? row.caption.substring(0, 150) + '...'
                : 'No Caption Provided'}
            </div>
          );
        }
      },
      {
        Header: 'Video Id',
        accessor: (row) => {
          return (
            <div className="flex flex-col items-center justify-center text-center w-full">
              <h2>{row.id}</h2>
              <Button
                className="flex justify-center items-center mt-4"
                onClick={async () => await copyToClipboardAsync(row.id!)}
              >
                Copy Video Id <FaCopy className="ml-2" />
              </Button>
            </div>
          );
        }
      },
      {
        Header: 'Edit',
        accessor: 'id'
      }
    ];

    return videoGroupItemsTableColumns;
  }, []);

  if (isVideosLoading) {
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="h-full w-full justify-start flex p-2 overflow-y-auto overflow-x-hidden bg-white">
      <div className="flex-col w-full h-full items-center justify-start">
        <div className="font-inter text-3xl font-bold flex justify-center mt-4">
          Videos Management
        </div>
        <div className="font-inter text-xl flex justify-center mt-4 italic">
          (Kindly search for a video below to get Video Id or Delete)
        </div>
        <DndProvider backend={HTML5Backend}>
          <Table
            tableColumns={videoGroupColumns as Column<IVideo>[]}
            searchPlaceholderText={'Videos'}
            entities={allVideos ?? []}
            isFullView
            onChangeSearchValue={setAllVideosNameQueryValue}
            rowHeight={'h-42'}
            shouldShowOrderArrows={false}
            onClickDeleteIcon={(video) => setVideoIdToDelete(video.id)}
          />
        </DndProvider>
      </div>
      {!!videoIdToDelete && (
        <ConfirmationPopup
          isModalOpen={!!videoIdToDelete}
          handleClickConfirmButton={async () => await handleDeleteVideoAsync()}
          closeModal={() => setVideoIdToDelete(undefined)}
          popupLabel={`Delete this Video?`}
          confirmButtonText="Delete Video"
          isErrorButton={true}
        >
          <div className="font-nunito text-primaryCharcoal font-normal text-base">
            If a Video is permanently deleted, it cannot be undone. Are you sure
            you want to delete this Video?
          </div>
        </ConfirmationPopup>
      )}
    </div>
  );
}

export default VideoContainer;
