import { IVideo } from 'Colugo/interfaces/video/IVideo';
import { MdDelete } from 'react-icons/md';
import ReactPlayer from 'react-player';
import { ImageKit } from 'utility/general/imageKit';

type Props = {
  videos: IVideo[];
  addVideo?: (video: IVideo) => void;
  deleteVideo?: () => void;
};

function VideoView(props: Props) {
  const { videos, addVideo, deleteVideo } = props;
  return (
    <div>
      {videos.map((video, i) => (
        <div key={i} className="flex mt-4 gap-4 items-start bg-theme3">
          <ReactPlayer
            url={ImageKit.withSizing(ImageKit.convertVideoUrl(video.url!))}
            width={300}
            height={300}
            style={{
              border: '2px solid #27AF96',
              borderRadius: '10px'
            }}
            controls
          />
          <div className="flex flex-col">
            {video.title && (
              <div className="mt-2">
                <span className="font-semibold">Title: </span>
                {video.title}
              </div>
            )}
            {video.caption && (
              <div className="mt-2">
                <span className="font-semibold">Caption: </span>
                {video.caption}
              </div>
            )}
          </div>
          {addVideo && (
            <div
              className="flex bg-primaryTeal text-white rounded-md ml-4 hover:opacity-75 justify-center items-center px-6 py-2 mt-2 cursor-pointer"
              onClick={() => {
                addVideo(video);
              }}
            >
              Add
            </div>
          )}

          {deleteVideo && (
            <div
              className="flex bg-red-500 text-white rounded-md ml-4 hover:opacity-75 justify-center items-center px-6 py-2 mt-2 cursor-pointer"
              onClick={() => {
                deleteVideo();
              }}
            >
              Remove <MdDelete size={24} className="ml-2" />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default VideoView;
