import { IBlock } from 'Colugo/interfaces/lobby/discover/blocks';
import { useReqListBlocks } from 'Colugo/operations/lobby';
import { BlockType } from 'Colugo/interfaces/lobby/discover/enums';
import {
  FlyoutMenu,
  FlyoutMenuItem,
  Spinner
} from '@bindystreet/bindystreet.kit.react';
import { toast } from 'react-toastify';
import { isEqual } from 'lodash';
import React, { useState } from 'react';

type Props = {
  block: IBlock;
  onBlockSelected?: (block: IBlock) => void;
};

function ExistingBlockSelector(props: Props) {
  // Props
  const { block, onBlockSelected } = props;

  // State
  const [isBlockSelectorOpen, setIsBlockSelectorOpen] =
    useState<boolean>(false);

  const {
    data: guideSpotsBlocks,
    isLoading: isGuideSpotBlockLoading,
    isError: isGuideSpotsBlockError
  } = useReqListBlocks(
    block.type === BlockType.GuideSpots ? block.type : undefined
  );

  const {
    data: entitiesGroupBlocks,
    isLoading: isEntitiesGroupBlockLoading,
    isError: isEntitiesGroupBlockError
  } = useReqListBlocks(
    block.type === BlockType.EntitiesGroup ? block.type : undefined
  );

  const {
    data: videosGroupBlocks,
    isLoading: isVideosGroupBlockLoading,
    isError: isVideosGroupBlockError
  } = useReqListBlocks(
    block.type === BlockType.VideosGroup ? block.type : undefined
  );

  const {
    data: suggestedListingsBlocks,
    isLoading: isSuggestedListingBlocksLoading,
    isError: isSuggestedListingBlocksError
  } = useReqListBlocks(
    block.type === BlockType.SuggestedListings ? block.type : undefined
  );

  const {
    data: suggestedEventsBlocks,
    isLoading: isSuggestedEventBlocksLoading,
    isError: isSuggestedEventBlocksError
  } = useReqListBlocks(
    block.type === BlockType.SuggestedEvents ? block.type : undefined
  );
  const {
    data: suggestedGuidesBlocks,
    isLoading: isSuggestedGuideBlocksLoading,
    isError: isSuggestedGuideBlocksError
  } = useReqListBlocks(
    block.type === BlockType.SuggestedGuides ? block.type : undefined
  );

  function groupBlockFlyoutMenuItems(
    groupBlocks: IBlock[] | undefined,
    isGroupBlockError: boolean | undefined,
    entityName: string
  ): FlyoutMenuItem[] {
    if (!groupBlocks || isGroupBlockError) {
      toast.error(
        `Error retrieving ${entityName} group blocks from the server`
      );
      return [];
    }
    return groupBlocks.map((block) => {
      const flyoutMenuItem = {
        label: block.name,
        value: block.id
      } as FlyoutMenuItem;
      return flyoutMenuItem!;
    });
  }

  function displayBlockFlyoutMenuItems(type: BlockType) {
    switch (type) {
      case BlockType.GuideSpots:
        return groupBlockFlyoutMenuItems(
          guideSpotsBlocks,
          isGuideSpotsBlockError,
          'guide spots'
        );
      case BlockType.EntitiesGroup:
        return groupBlockFlyoutMenuItems(
          entitiesGroupBlocks,
          isEntitiesGroupBlockError,
          'entity'
        );
      case BlockType.VideosGroup:
        return groupBlockFlyoutMenuItems(
          videosGroupBlocks,
          isVideosGroupBlockError,
          'videos'
        );
      case BlockType.SuggestedListings:
        return groupBlockFlyoutMenuItems(
          suggestedListingsBlocks,
          isSuggestedListingBlocksError,
          'suggested listings'
        );
      case BlockType.SuggestedGuides:
        return groupBlockFlyoutMenuItems(
          suggestedGuidesBlocks,
          isSuggestedGuideBlocksError,
          'suggested guides'
        );
      case BlockType.SuggestedEvents:
        return groupBlockFlyoutMenuItems(
          suggestedEventsBlocks,
          isSuggestedEventBlocksError,
          'suggested events'
        );
      default:
        return [];
    }
  }

  return (
    <div>
      <span className="font-semibold">{'Block:'}</span>
      {(block.type === BlockType.EntitiesGroup &&
        isEntitiesGroupBlockLoading) ||
      (block.type === BlockType.GuideSpots && isGuideSpotBlockLoading) ||
      (block.type === BlockType.VideosGroup && isVideosGroupBlockLoading) ||
      (block.type === BlockType.SuggestedListings &&
        isSuggestedListingBlocksLoading) ||
      (block.type === BlockType.SuggestedEvents &&
        isSuggestedEventBlocksLoading) ||
      (block.type === BlockType.SuggestedGuides &&
        isSuggestedGuideBlocksLoading) ? (
        <Spinner />
      ) : (
        <FlyoutMenu
          activeLabel={block.name}
          isEqual={isEqual}
          size="xl"
          isMenuOpen={isBlockSelectorOpen}
          setIsMenuOpen={setIsBlockSelectorOpen}
          items={displayBlockFlyoutMenuItems(block.type!)}
          onChange={(e) =>
            onBlockSelected?.({
              ...block,
              name: e.label,
              id: e.value
            })
          }
          withSearch
        />
      )}
    </div>
  );
}

export default ExistingBlockSelector;
